html,
body {
  font-family: PingFang SC, Microsoft YaHei, sans-serif;
  font-size: 14px;
  line-height: 1;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
input {
  border: 0;
}
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.not-found {
  margin: 140px auto 100px;
  margin-top: 140px;
  width: 590px;
  height: 400px;
  background-image: url(../images/pic_404.png);
}
.custom-button .button {
  text-align: center;
  color: white;
  background-color: #55cec7;
  box-shadow: 0px 2px 8px 0px rgba(59, 178, 185, 0.35);
  cursor: pointer;
}
.custom-button .button:focus {
  outline: none;
}
.custom-button .button:active {
  background-color: #48c0b9;
}
.custom-button .normal {
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border-radius: 4px;
}
.custom-button .large {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  border-radius: 8px;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
header .header-body {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .header-body .logo {
  width: 122px;
  height: 38px;
  background-image: url(../images/logo/logo_nav_white.png);
}
header .header-body .top-nav-item {
  position: relative;
  display: inline-block;
  margin-left: 80px;
}
header .header-body .top-nav-item a {
  display: inline-block;
  height: 80px;
}
header .header-body .top-nav-item a .active {
  opacity: 1;
}
header .header-body .top-nav-item a > span {
  font-size: 16px;
  color: white;
  opacity: 0.6;
  line-height: 80px;
}
header .header-body .top-nav-item .extra {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  text-align: center;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 2px 10px 0px rgba(221, 221, 221, 0.25);
}
header .header-body .top-nav-item .extra .item a {
  display: block;
  font-size: 16px;
  color: #666666;
  height: 58px;
  line-height: 58px;
}
header .header-body .top-nav-item .extra .item-active a {
  color: #002B9E;
  background-color: #e2f2fc;
}
header .header-body .top-nav-item .extra .item:hover {
  background-color: #e2f2fc;
}
header .header-body .top-nav-item .extra .item:hover a {
  color: #002B9E;
}
header .header-body .top-nav-item:hover .extra {
  opacity: 1;
  visibility: visible;
}
.header-light {
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 0.35);
}
.header-light .header-body .logo {
  background-image: url(../images/logo/logo_nav_blue.png);
}
.header-light .header-body .top-nav a > span {
  color: black;
}
footer {
  background: linear-gradient(135deg, #102280 0%, #2e68cc 98%);
}
footer a,
footer a:hover {
  color: white;
}
footer .footer-body {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  color: white;
}
footer .footer-body .footer {
  height: 344px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .footer-body .footer-left {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
footer .footer-body .footer-left .footer-item {
  display: inline-block;
  padding-right: 5%;
}
footer .footer-body .footer-left .footer-item-title {
  font-size: 18px;
}
footer .footer-body .footer-left .footer-item-content {
  margin-top: 30px;
  line-height: 38px;
}
footer .footer-body .footer-left .footer-item-content .content {
  opacity: 0.6;
}
footer .footer-body .footer-right {
  width: 122px;
}
footer .footer-body .footer-right .qrcode {
  width: 122px;
  height: 122px;
  background-image: url(../images/home/qrcode.jpg);
  background-size: 100%;
}
footer .footer-body .footer-right .text {
  margin-top: 15px;
  font-size: 12px;
}
footer .footer-body .footer-extra {
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  opacity: 0.5;
}
.sliderComponent {
  z-index: 0;
}
.sliderComponent .banner-dots {
  position: absolute;
  bottom: 140px;
  left: 0px;
}
.sliderComponent .banner-dots .dot {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-left: 10px;
}
.sliderComponent .banner-dots .active {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: #ffffff;
}
.fade-in-banner {
  position: relative;
  height: 696px;
  overflow: hidden;
}
.fade-in-banner .hide {
  z-index: 0 !important;
  opacity: 0 !important;
}
.fade-in-banner .banner-wrap {
  height: 100%;
  position: relative;
}
.fade-in-banner .banner-item {
  z-index: 1;
  transition: all 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
}
.fade-in-banner .banner-dots {
  position: absolute;
  bottom: 140px;
  left: 0px;
  z-index: 3;
}
.fade-in-banner .banner-dots .dot {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-left: 10px;
  cursor: pointer;
}
.fade-in-banner .banner-dots .active {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: #ffffff;
}
.custom-tab .switch-title {
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  display: inline-block;
  width: 150px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  cursor: pointer;
}
.custom-tab .switch-title:first-child {
  border-left: 1px solid #eeeeee;
}
.custom-tab .title-active {
  color: white;
  background-color: #55cec7;
}
.custom-tab .tab-box {
  margin-top: 30px;
}
.custom-tab .tab-box .item {
  display: none;
  transition: all ease-in-out 0.5s;
}
.custom-tab .tab-box .item-active {
  display: block;
}
.customSide-tab {
  display: flex;
  justify-content: left;
}
.customSide-tab .switch-title {
  width: 244px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 8px 0px rgba(85, 199, 206, 0.35);
  cursor: pointer;
}
.customSide-tab .title-active {
  color: white;
  background-color: #55cec7;
  border: none;
}
.customSide-tab .tab-box {
  flex: 1;
  margin-left: 50px;
  border-left: 1px solid #eeeeee;
  padding-left: 50px;
  position: relative;
}
.customSide-tab .tab-box .item {
  display: none;
  transition: all ease-in-out 0.5s;
}
.customSide-tab .tab-box .item-active {
  display: block;
}
.modal-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-items: center;
}
.modal-page-container {
  margin: 0 auto;
  width: 434px;
  max-height: calc(100% - 10px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 30px 50px;
  background-color: white;
  border-radius: 10px;
  position: relative;
}
.modal-page-container-close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.dtchain-pagination a {
  color: #333333;
}
.dtchain-pagination > li {
  list-style: none;
}
.dtchain-pagination-disabled,
.dtchain-pagination-disabled:hover,
.dtchain-pagination-disabled:focus {
  cursor: not-allowed;
}
.dtchain-pagination-disabled a,
.dtchain-pagination-disabled:hover a,
.dtchain-pagination-disabled:focus a,
.dtchain-pagination-disabled .dtchain-pagination-item-link,
.dtchain-pagination-disabled:hover .dtchain-pagination-item-link,
.dtchain-pagination-disabled:focus .dtchain-pagination-item-link {
  color: #999999;
  border-color: white;
  cursor: not-allowed;
}
.dtchain-pagination-prev,
.dtchain-pagination-next,
.dtchain-pagination-item {
  box-sizing: border-box;
  background: white;
  border: 1px solid #eeeeee;
}
.dtchain-pagination-prev:focus,
.dtchain-pagination-next:focus,
.dtchain-pagination-item:focus,
.dtchain-pagination-prev:hover,
.dtchain-pagination-next:hover,
.dtchain-pagination-item:hover {
  color: #ffffff;
  border-color: #55cec7;
  background: #55cec7;
}
.dtchain-pagination-jump-prev:focus,
.dtchain-pagination-jump-next:focus {
  outline: none;
}
.dtchain-pagination-prev,
.dtchain-pagination-next,
.dtchain-pagination-item,
.dtchain-pagination-jump-prev,
.dtchain-pagination-jump-next {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}
.dtchain-pagination-prev,
.dtchain-pagination-next {
  width: 74px;
  height: 38px;
  line-height: 38px;
  text-align: center;
}
.dtchain-pagination-item {
  width: 38px;
  height: 38px;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
}
.dtchain-pagination-item-active {
  color: #ffffff;
  border-color: #55cec7;
  background: #55cec7;
}
.dtchain-input {
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  padding: 13px;
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
}
.dtchain-input-error {
  border: 1px solid red;
}
.dtchain-input-error:focus {
  outline: none;
}
textarea.dtchain-input {
  max-width: 100%;
  min-width: 100%;
  height: 82px;
  max-height: 200px;
  min-height: 60px;
  line-height: 1.5;
  vertical-align: bottom;
  resize: none;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.title-component {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.title-component-icon {
  display: inline-block;
  color: #5576FF;
  margin-right: 15px;
  vertical-align: middle;
}
.title-component .text {
  font-weight: 600;
  color: #202020;
  line-height: 22px;
  vertical-align: middle;
}
.table-container {
  border-radius: 5px;
  background-color: #fff;
}
.table-container-shadow {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 6px 1px #e3e3e3;
  overflow: hidden;
}
.gallery-list {
  text-align: center;
}
.gallery-item-delete {
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
  color: red;
  padding: 2px;
  border-radius: 50%;
}
.gallery-item-delete:hover {
  color: white;
  background-color: red;
}
.gallery-item-box {
  position: relative;
}
.gallery-item-eye {
  position: absolute;
  right: 10px;
  bottom: 0px;
  cursor: pointer;
  border-radius: 50%;
}
.gallery-nm-item,
.gallery-sm-item {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.gallery-nm-item a,
.gallery-sm-item a {
  display: flex;
  margin: 10px;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  align-items: center;
}
.gallery-nm-item {
  width: 120px;
}
.gallery-nm-item a {
  width: 100px;
  height: 100px;
}
.gallery-nm-item a img {
  max-width: 90px;
  height: 90px;
}
.gallery-sm-item {
  width: 70px;
}
.gallery-sm-item a {
  width: 50px;
  height: 60px;
}
.gallery-sm-item a img {
  max-width: 40px;
  height: 40px;
}
.gallery-text {
  padding: 0 10px;
  font-size: 12px;
  word-break: break-all;
  text-align: center;
  line-height: 1;
  color: #999;
}
@keyframes float {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 10px);
  }
}
@keyframes fadeOutDown {
  from {
    transform: translate(0, -100px);
  }
  to {
    transform: translate(0, 50px);
    opacity: 0;
  }
}
.fadeInRightBottom-before {
  opacity: 0;
  transform: translate(100%, 100%);
}
.fadeInLeftBottom-before {
  opacity: 0;
  transform: translate(-100%, 100%);
}
.fadeInLeftTop-before {
  opacity: 0;
  transform: translate(-100%, -100%);
}
.fadeInRightTop-before {
  opacity: 0;
  transform: translate(100%, -100%);
}
.fadeInRightBottom-after,
.fadeInLeftBottom-after,
.fadeInLeftTop-after,
.fadeInRightTop-after {
  opacity: 1;
  transform: none;
  transition: opacity 1000ms, transform 1000ms;
}
.banner-image {
  height: 696px;
}
.banner-image .banner-body {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}
.banner-image .banner-body .content {
  z-index: 2;
  position: relative;
  color: white;
}
.banner-image .banner-body .content .title {
  font-size: 42px;
  font-weight: bold;
}
.banner-image .banner-body .content .description {
  margin-top: 25px;
  font-size: 20px;
  line-height: 30px;
  width: 600px;
}
.banner-image .banner-body .content .button {
  margin-top: 40px;
  width: 186px;
}
.image-block {
  background: linear-gradient(135deg, #102280 0%, #2e68cc 98%);
}
.image-block .banner-body .content {
  padding-top: 220px;
}
.image-block .banner-body .pic-box {
  z-index: 0;
  position: absolute;
  bottom: 108px;
  right: 188px;
  width: 645px;
  height: 374px;
  background-image: url(../images/home/image_block_line.png);
  background-size: 100%;
}
.image-block .banner-body .pic-box .pic {
  z-index: 1;
  position: absolute;
  top: -80px;
  left: 265px;
  width: 568px;
  height: 562px;
  background-image: url(../images/home/image_block.png);
  background-size: 100%;
  animation: float 1.5s ease-in-out 0s infinite alternate;
}
.image-financial {
  background: #081071;
}
.image-financial .banner-body .content {
  padding-top: 180px;
}
.image-financial .banner-body .content .info-group {
  padding-top: 20px;
  width: 500px;
}
.image-financial .banner-body .content .info-group-item {
  margin-top: 10px;
  display: inline-block;
  width: 50%;
  padding-left: 34px;
  height: 20px;
  font-size: 20px;
  background-image: url(../images/home/banner_hook.png);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: left center;
}
.image-financial .banner-body .pic-box {
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: 100%;
}
.image-financial .banner-body .pic-box .pic {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 135px;
  width: 456px;
  height: 432px;
  background-image: url(../images/home/image_financial.png);
  background-size: 100%;
  animation: float 1.5s ease-in-out 0s infinite alternate;
}
.image-financial .banner-body .pic-box .pic-extra {
  position: absolute;
  width: 34px;
  height: 228px;
  background-image: url(../images/home/banner_light.png);
  animation: fadeOutDown 2s ease-in-out 0s infinite normal;
}
.image-financial .banner-body .pic-box .pic .position-1 {
  top: -24px;
  left: 34px;
}
.image-financial .banner-body .pic-box .pic .position-2 {
  top: 12px;
  right: 6px;
}
.image-financial .banner-body .pic-box .pic .position-3 {
  left: -97px;
  bottom: 27px;
}
.image-financial .banner-body .pic-box .pic .position-4 {
  right: -92px;
  bottom: -25px;
}
.image-technology {
  background: #08084A;
}
.image-technology .banner-body .content {
  padding-top: 250px;
}
.image-technology .banner-body .pic-box {
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: 100%;
}
.image-technology .banner-body .pic-box .pic {
  z-index: 1;
  position: absolute;
  right: 20px;
  bottom: 100px;
  width: 314px;
  height: 436px;
  background-image: url(../images/home/image_technology.png);
  background-size: 100%;
  animation: float 1.5s ease-in-out 0s infinite alternate;
}
.home .block-info {
  position: relative;
  z-index: 2;
  margin-top: -1px;
  height: 590px;
  background: #F5F4FF;
  overflow: hidden;
}
.home .block-info-bg-top {
  position: relative;
  z-index: 2;
  margin-top: -80px;
  height: 80px;
  background-image: url(../images/home/bg_banner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.home .block-info .block-box .title {
  padding-top: 40px;
  font-size: 38px;
  text-align: center;
  color: #333333;
}
.home .block-info .block-box .block-body {
  margin-top: 20px;
}
.home .block-info .block-box .block-body .block-circle {
  position: relative;
  margin: 0 auto;
  margin-top: 200px;
  width: 558px;
  height: 139px;
  background-size: 100%;
  background-image: url(../images/home/circle_big.png);
}
.home .block-info .block-box .block-body .block-circle .bg-line {
  position: relative;
  top: 25px;
}
.home .block-info .block-box .block-body .block-circle .bg-line .line-center {
  height: 20px;
  width: 154px;
  background-image: url(../images/home/line_center.png);
}
.home .block-info .block-box .block-body .block-circle .bg-line .left {
  position: absolute;
  left: -170px;
}
.home .block-info .block-box .block-body .block-circle .bg-line .left .top {
  height: 20px;
  width: 154px;
  background-image: url(../images/home/line_center.png);
  transform: rotateY(180deg);
}
.home .block-info .block-box .block-body .block-circle .bg-line .left .bottom {
  height: 20px;
  width: 154px;
  background-image: url(../images/home/line_center.png);
  margin-top: 36px;
  transform: rotate(180deg);
}
.home .block-info .block-box .block-body .block-circle .bg-line .right {
  position: absolute;
  right: -170px;
}
.home .block-info .block-box .block-body .block-circle .bg-line .right .top {
  height: 20px;
  width: 154px;
  background-image: url(../images/home/line_center.png);
}
.home .block-info .block-box .block-body .block-circle .bg-line .right .bottom {
  height: 20px;
  width: 154px;
  background-image: url(../images/home/line_center.png);
  margin-top: 36px;
  transform: rotateX(180deg);
}
.home .block-info .block-box .block-body .block-circle .block-center {
  position: absolute;
  bottom: 0px;
  left: 210px;
  width: 156px;
  height: 297px;
  background-image: url(../images/home/icon_block_center.png);
  background-size: 100%;
  animation: float 1.5s ease-in-out 0s infinite alternate;
}
.home .block-info .block-box .block-body .block-circle .block-line {
  width: 95px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/home/line.png);
}
.home .block-info .block-box .block-body .block-circle .node {
  position: absolute;
  top: 0;
  left: 0;
  width: 82px;
  height: 98px;
  background-image: url(../images/home/icon_node_big.png);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 10;
}
.home .block-info .block-box .block-body .block-circle .block-item {
  width: 82px;
  height: 64px;
  cursor: pointer;
}
.home .block-info .block-box .block-body .block-circle .block-item:hover .node {
  transform: scale(1.3);
  transition: transform 1000ms;
}
.home .block-info .block-box .block-body .block-circle .block-item:hover .node-text {
  max-height: 117px;
  width: 230px;
  text-align: left;
}
.home .block-info .block-box .block-body .block-circle .block-item:hover .node-text .node-text-title:after {
  opacity: 1;
  margin: 6px 0 12px;
}
.home .block-info .block-box .block-body .block-circle .block-item:hover .node-text .node-text-info {
  opacity: 1;
  transition: all 1000ms 500ms;
  visibility: visible;
}
.home .block-info .block-box .block-body .block-circle .left-top {
  position: absolute;
  top: -88px;
  left: -18px;
}
.home .block-info .block-box .block-body .block-circle .left-top .line {
  position: absolute;
  right: -100px;
  top: 50%;
  width: 95px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/home/line.png);
  transform: rotateY(180deg);
}
.home .block-info .block-box .block-body .block-circle .right-top {
  position: absolute;
  top: -88px;
  right: -18px;
}
.home .block-info .block-box .block-body .block-circle .right-top .line {
  position: absolute;
  left: -100px;
  top: 50%;
  width: 95px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/home/line.png);
}
.home .block-info .block-box .block-body .block-circle .left-bottom {
  position: absolute;
  bottom: -74px;
  left: -18px;
}
.home .block-info .block-box .block-body .block-circle .left-bottom .line {
  position: absolute;
  right: -106px;
  top: -10px;
  width: 95px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/home/line.png);
  transform: rotate(180deg);
}
.home .block-info .block-box .block-body .block-circle .right-bottom {
  position: absolute;
  bottom: -74px;
  right: -18px;
}
.home .block-info .block-box .block-body .block-circle .right-bottom .line {
  position: absolute;
  left: -106px;
  top: -10px;
  width: 95px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/home/line.png);
  transform: rotateX(180deg);
}
.home .block-info .block-box .block-body .block-circle .node-text {
  position: absolute;
  z-index: 0;
  padding: 0 15px;
  padding: 14px;
  line-height: 1.5;
  font-size: 22px;
  color: #ffffff;
  background-image: linear-gradient(90deg, #45aaf9 0%, #02ccd4 100%);
  border-radius: 8px;
  width: 180px;
  transition: width 500ms, height 500ms;
  box-sizing: content-box;
  max-height: 33px;
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(35, 145, 179, 0.35);
}
.home .block-info .block-box .block-body .block-circle .node-text-title:after {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  background-color: white;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.home .block-info .block-box .block-body .block-circle .node-text-info {
  font-size: 14px;
  opacity: 0;
  line-height: 1.5;
  visibility: hidden;
}
.home .block-info .block-box .block-body .block-circle .node-text:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 2;
  left: 1px;
  top: 50%;
  transform: translate(-100%, -50%);
  border-style: solid;
  border-right-width: 8px;
  border-left-width: 4px;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-color: #45AAF9;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.home .block-info .block-box .block-body .block-circle .isLeftSide {
  left: -10px;
  top: 50%;
  transform: translate(-100%, -50%);
}
.home .block-info .block-box .block-body .block-circle .isRightSide {
  right: -10px;
  top: 50%;
  transform: translate(100%, -50%);
}
.home .block-info .block-box .block-body .block-circle .isLeftSide:before {
  right: 1px;
  border-left-width: 8px;
  border-right-width: 4px;
  border-right-color: transparent;
  border-left-color: #02CCD4;
  transform: translate(100%, -50%);
}
.home .production {
  background-image: url(../images/home/bg_production.png);
  background-position: center;
  margin-bottom: 100px;
}
.home .production-box {
  padding: 60px 0;
}
.home .production-box .title {
  color: white;
  text-align: center;
  font-size: 38px;
}
.home .production-body {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.home .production-body .item {
  position: relative;
  box-sizing: border-box;
  padding: 0 40px;
  height: 522px;
  width: 264px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 2px 24px 0px rgba(221, 221, 221, 0.35);
}
.home .production-body .item .icon {
  margin: 0 auto;
  margin-top: 110px;
  transition: all ease-out 0.4s;
}
.home .production-body .item .icon img {
  width: 144px;
  height: 144px;
  transition: all ease-out 0.4s;
}
.home .production-body .item .title {
  margin-top: 16px;
  font-size: 22px;
  color: #333333;
}
.home .production-body .item .title:after {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  background-color: white;
  margin: 0 auto;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.home .production-body .item .content {
  position: relative;
}
.home .production-body .item .content .info {
  padding-top: 40px;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  position: relative;
  top: 0;
  opacity: 1;
  transition: all ease-in-out 0.3s;
}
.home .production-body .item .content .extra {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  transition: opacity ease-out 0.3s;
}
.home .production-body .item .content .extra .list {
  color: white;
  font-size: 14px;
  line-height: 40px;
  opacity: 0.8;
}
.home .production-body .item .button {
  position: absolute;
  left: 50%;
  font-size: 16px;
  transform: translateX(-50%);
  bottom: 30px;
  margin: 0 auto;
  color: #094eb9;
  width: 136px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: white;
  border-radius: 6px;
  opacity: 0;
}
.home .production-body .item:hover {
  background-color: #6295E5;
  color: white;
  box-shadow: 0px 10px 24px 0px rgba(6, 18, 113, 0.47);
}
.home .production-body .item:hover .icon {
  margin: 0 auto;
  margin-top: 20px;
}
.home .production-body .item:hover .icon img {
  width: 82px;
  height: 82px;
}
.home .production-body .item:hover .title {
  color: white;
}
.home .production-body .item:hover .title:after {
  opacity: 1;
  margin: 10px auto 20px;
}
.home .production-body .item:hover .content .info {
  top: 100px;
  opacity: 0;
}
.home .production-body .item:hover .content .extra {
  visibility: visible;
  opacity: 1;
}
.home .production-body .item:hover .button {
  opacity: 1;
}
.home .home-news {
  margin-bottom: 100px;
  background-color: white;
}
.home .home-news-title {
  color: #333333;
  font-size: 38px;
  text-align: center;
}
.home .home-news-body {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.home .home-news-body .item:hover {
  box-shadow: 0px 2px 12px 0px rgba(221, 221, 221, 0.5);
}
.home .home-news-body .item {
  max-width: 364px;
  cursor: pointer;
  border: 1px solid #EEEEEE;
  width: 33.3%;
}
.home .home-news-body .item img {
  width: 100%;
  height: 224px;
}
.home .home-news-body .item .content {
  padding: 25px 15px;
}
.home .home-news-body .item .content .title {
  font-size: 22px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home .home-news-body .item .content .sub-title {
  margin-top: 20px;
  font-size: 14px;
  color: #999999;
}
.home .home-news-button {
  margin: 0 auto;
  margin-top: 60px;
  width: 186px;
}
.home .partner {
  margin: 0 auto 100px;
}
.home .partner-title {
  color: #333333;
  font-size: 38px;
  text-align: center;
}
.home .partner-body {
  margin-top: 80px;
}
.home .partner-body .partner-pic-row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.about_us_banner {
  background-image: url(../images/about/banner.png);
  height: 400px;
}
.about_us {
  padding-top: 100px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
.about_us .section {
  padding-bottom: 100px;
}
.about_us .section .title {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 60px;
  color: #333333;
}
.about_us .section .description {
  font-size: 16px;
  line-height: 33px;
  color: #333333;
  font-weight: 400;
}
.about_us .section .contact-list {
  display: flex;
  justify-content: space-between;
}
.about_us .section .contact-list .contact {
  width: 350px;
  height: 252px;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 0.35);
  border-radius: 2px;
  padding: 0 30px;
  box-sizing: border-box;
}
.about_us .section .contact-list .contact-way {
  display: flex;
  align-items: center;
  padding: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}
.about_us .section .contact-list .contact-way img {
  margin-right: 20px;
}
.about_us .section .contact-list .contact-way-title {
  font-size: 22px;
  color: #333333;
}
.about_us .section .contact-list .contact-content {
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
.about_us .section .contact-list .contact-content-detail {
  margin-bottom: 20px;
}
.about_us .section .contact-list .contact-content-detail img {
  max-width: 53px;
  max-height: 53px;
  vertical-align: top;
}
.about_us .section .about-timeline {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.about_us .section .about-timeline-date {
  width: 160px;
  text-align: right;
  font-size: 18px;
  color: #333333;
}
.about_us .section .about-timeline-content {
  width: 760px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding: 30px 19px;
}
.about_us .section .about-timeline-content-hover {
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(93, 93, 93, 0.2);
}
.about_us .section .about-timeline-status {
  display: flex;
  margin: 0px 14px 0px 24px;
  position: relative;
  justify-content: center;
  align-items: flex-start;
}
.about_us .section .about-timeline-status .status-tail-horizontal {
  width: 160px;
  height: 1px;
  background: #eeeeee;
}
.about_us .section .about-timeline-status .status-tail-vertical {
  position: absolute;
  left: calc(50% - 0.5px);
  width: 1px;
  height: 102px;
  background: #eeeeee;
}
.about_us .section .about-timeline-status .status-circle-hover {
  width: 26px;
  height: 26px;
  left: calc(50% - 13px);
  border-radius: 50%;
  background-color: #55cec7;
}
.about_us .section .about-timeline-status .status-circle {
  position: absolute;
  left: calc(50% - 13px);
  top: -13px;
  max-width: 26px;
  max-height: 26px;
}
.contact-modal {
  margin: 0 auto;
  width: 434px;
  max-height: calc(100% - 10px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 30px 50px;
  background-color: white;
  border-radius: 10px;
  position: relative;
}
.contact-modal-close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.contact-modal-form-item {
  margin: 15px 0px;
}
.contact-modal-form-item-err-info {
  margin: 4px 0px;
  color: red;
}
.contact-modal-form-item-label,
.contact-modal-form-item-label-require {
  font-size: 14px;
  color: #666666;
  margin-bottom: 7px;
}
.contact-modal-form-item-label {
  padding-left: 10px;
}
.contact-modal-form-item-label-require::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: '*';
}
.contact-modal-title {
  text-align: center;
  font-size: 22px;
  color: #333333;
}
.contact-modal-content-button {
  margin-top: 34px;
}
.news_banner {
  height: 300px;
  background-image: url(../images/news/banner.png);
}
.news {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
}
.news-pagination {
  display: flex;
  justify-content: center;
}
.news .news-list .new-item {
  height: 206px;
  margin-bottom: 72px;
  display: flex;
  justify-content: flex-start;
}
.news .news-list .new-item-img {
  width: 334px;
  margin-right: 35px;
}
.news .news-list .new-item-details {
  position: relative;
  height: 100%;
  width: 831px;
}
.news .news-list .new-item-details-title {
  margin: 23px 0px;
}
.news .news-list .new-item-details-title a {
  font-size: 22px;
  color: #333333;
}
.news .news-list .new-item-details-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #666666;
  line-height: 21px;
}
.news .news-list .new-item-details-date {
  position: absolute;
  bottom: 21px;
  font-size: 12px;
  color: #999999;
}
.news-detail {
  background-color: #f4f4f4;
  padding: 39px 0px 45px 0px;
}
.news-detail-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
  padding: 55px 63px 36px 63px;
}
.news-detail-title {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
}
.news-detail-date {
  margin: 18px 0px;
  font-size: 14px;
  color: #999999;
}
.news-detail-content {
  padding: 20px 0px 100px 0px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
  box-sizing: border-box;
}
.news-detail-content img,
.news-detail-content audio,
.news-detail-content video {
  max-width: 100%;
  height: auto;
}
.news-detail-content p {
  white-space: pre-wrap;
  min-height: 1em;
}
.news-detail-content pre {
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
}
.news-detail-content blockquote {
  margin: 0;
  padding: 15px;
  background-color: #f1f1f1;
  border-left: 3px solid #d1d1d1;
}
.news-detail-others {
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
}
.news-detail-others-pre,
.news-detail-others-next {
  font-size: 16px;
  color: #999999;
  line-height: 24px;
}
.news-detail-others-pre:hover,
.news-detail-others-next:hover {
  color: #55cec7;
}
.admin-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 78px;
}
.admin-header .logo-wrap {
  display: flex;
  align-items: center;
}
.admin-header .logo-wrap .logo-title {
  padding-left: 20px;
  font-size: 16px;
  padding-top: 17px;
  opacity: 0.5;
}
.admin-header .logo {
  height: 32px;
}
.login {
  display: flex;
  border-top: 1px solid #e8e8e8;
}
.login .background {
  width: 60vw;
  height: calc(100vh - 79px);
  background-image: url("../images/admin/bg.png");
  background-size: 100% 100%;
  position: relative;
}
.login .background .illustration {
  position: absolute;
  top: 20vh;
  left: 40%;
  transform: translateX(-50%);
  width: 65vh;
  height: 47vh;
  max-width: 50vw;
  max-height: 35vw;
  background-image: url("../images/admin/Illustration.png");
  background-size: 100% 100%;
}
.login-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 403px;
  position: relative;
}
.login-content-detail {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 403px;
}
.login-content .footer {
  position: absolute;
  bottom: 30px;
  height: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #888888;
  line-height: 20px;
}
.login-content-title {
  width: 403px;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  color: #202020;
}
.login-content-link {
  display: flex;
  justify-content: space-between;
}
.login-content-link a {
  text-align: center;
  display: inline-block;
  color: #999;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
}
.login-content .decorator {
  width: 94px;
  height: 8px;
  background: #134add;
  border-radius: 7px;
  margin: 20px 0 35px;
}
.login-content #logo {
  position: absolute;
  top: 25px;
  right: 0;
  width: 90px;
  height: 28px;
  background-image: url("../images/admin/logo.png");
  background-size: 100% 100%;
}
.login-content .ant-form-item {
  margin-bottom: 20px;
}
#side-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.admin-news .admin-table-item,
.admin-advisory .admin-table-item {
  vertical-align: middle;
}
.admin-news .admin-table-item-actions button,
.admin-advisory .admin-table-item-actions button {
  padding-left: 0px;
}
.admin-news-cover {
  max-width: 200px;
  max-height: 80px;
  cursor: pointer;
}
.admin-news-edit .gallery-list {
  text-align: left;
}
.admin-news-edit .ant-upload-list-item {
  display: none;
}
.admin-news-edit-Editor {
  background-color: #fff;
}
.admin-news-edit-button {
  display: flex;
  justify-content: center;
}
.admin-news-edit-button button {
  margin: 0px 50px;
}
.user-info {
  cursor: pointer;
}
.product {
  margin-top: 80px;
}
.product-banner {
  height: 470px;
  background-color: #094EB9;
  overflow: hidden;
}
.product-banner .banner-body {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.product-banner .banner-body .content {
  position: relative;
  z-index: 1;
  padding-top: 180px;
  color: white;
}
.product-banner .banner-body .content .title {
  font-size: 40px;
}
.product-banner .banner-body .content .description {
  width: 450px;
  margin-top: 20px;
  line-height: 1.5;
}
.product-banner .banner-body .pic_gyl {
  z-index: 0;
  position: absolute;
  right: -147px;
  bottom: 0;
  width: 776px;
  height: 460px;
  background-image: url(../images/product/financial/pic_banner.png);
  background-size: 100%;
}
.product-banner .banner-body .pic_skqkl {
  z-index: 0;
  position: absolute;
  right: -161px;
  bottom: 0;
  width: 776px;
  height: 390px;
  background-image: url(../images/product/block/pic_bnaner.png);
  background-size: 100%;
}
.product-banner .banner-body .pic_szzc {
  z-index: 0;
  position: absolute;
  right: -66px;
  bottom: 22px;
  width: 484px;
  height: 383px;
  background-image: url(../images/product/assets/pic_banner.png);
  background-size: 100%;
}
.product-banner .banner-body .pic_szczjh {
  z-index: 0;
  position: absolute;
  right: 18px;
  bottom: 56px;
  width: 352px;
  height: 345px;
  background-image: url(../images/product/transfer/pic_banner.png);
  background-size: 100%;
}
.product-plan {
  padding-top: 90px;
}
.product-plan .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-plan .plan-box {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.product-plan .plan-box-item {
  text-align: center;
  width: 170px;
}
.product-plan .plan-box-item .plan-icon {
  margin: 0 auto;
}
.product-plan .plan-box-item .plan-icon img {
  width: 80px;
  height: 80px;
}
.product-plan .plan-box-item .plan-title {
  margin-top: 30px;
  font-size: 22px;
  color: #333333;
}
.product-plan .plan-box-item .plan-des {
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
}
.product-plan .plan-extra {
  margin-top: 60px;
  padding: 30px 60px;
  background-color: rgba(244, 244, 244, 0.32);
}
.product-plan .plan-extra-title {
  font-size: 22px;
  color: #55cec7;
}
.product-plan .plan-extra-body li {
  float: left;
  width: 33%;
  margin-top: 30px;
  color: #333333;
  padding-left: 30px;
  background-image: url(../images/product/financial/dot.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: left center;
}
.product-business {
  padding-top: 90px;
}
.product-business .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-business .business-box {
  margin-top: 50px;
  padding: 40px 100px;
  box-shadow: 0px 2px 12px 0px rgba(192, 191, 233, 0.3);
  text-align: center;
  min-width: 750px;
}
.product-business .business-box img {
  width: 100%;
}
.product-worth {
  margin-top: 70px;
  padding: 90px 0 50px;
  background-color: #f4f4f4;
}
.product-worth .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-worth .worth-box {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.product-worth .worth-box-item {
  width: 30%;
  background-color: white;
  padding: 40px;
  box-shadow: 0px 2px 12px 0px rgba(221, 221, 221, 0.35);
  background-image: url(../images/product/financial/worth_bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}
.product-worth .worth-box-item .item-title {
  font-size: 22px;
  color: #333333;
}
.product-worth .worth-box-item .info {
  position: relative;
  margin-top: 20px;
  padding-left: 20px;
  line-height: 1.5;
}
.product-worth .worth-box-item .info::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #333333;
  border-radius: 50%;
}
.product-case {
  padding: 90px 0 50px;
  background-color: #FAFBFD;
}
.product-case .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-case .case-box {
  margin-top: 60px;
  padding: 50px;
  background-color: white;
  box-shadow: 0px 2px 12px 0px rgba(221, 221, 221, 0.35);
}
.product-case .case-box .case-item-title {
  margin-top: 40px;
  font-size: 22px;
  color: #333333;
  margin-bottom: 20px;
}
.product-case .case-box .case-item-title:first-child {
  margin-top: 0;
}
.product-case .case-box .case-item-info {
  color: #666666;
  line-height: 1.5;
}
.product-case .case-box .case-item-info img {
  width: 146px;
  height: 85px;
  margin-right: 40px;
}
.product-access {
  padding-top: 90px;
}
.product-access .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-access .access-box {
  margin: 60px 0 100px;
  display: flex;
  justify-content: space-between;
}
.product-access .access-box-item {
  text-align: center;
  width: 170px;
}
.product-access .access-box-item .access-icon {
  margin: 0 auto;
  margin-bottom: 30px;
}
.product-access .access-box-item .access-icon img {
  width: 102px;
  height: 116px;
}
.product-access .access-box-item .access-title {
  font-size: 22px;
  color: #333333;
  line-height: 1.5;
}
.product-safe {
  margin-top: 100px;
  margin-bottom: 30px;
}
.product-safe .title {
  font-size: 38px;
  color: #333333;
  text-align: center;
}
.product-safe .safe-box {
  margin-top: 60px;
}
.product-safe .safe-box-item:nth-child(2n) .box-item {
  justify-content: flex-end;
}
.product-safe .safe-box-item {
  margin-bottom: 100px;
  display: inline-block;
  width: 50%;
}
.product-safe .safe-box-item .box-item {
  display: flex;
  justify-content: flex-start;
}
.product-safe .safe-box-item .box-item .safe-pic {
  width: 72px;
  height: 72px;
}
.product-safe .safe-box-item .box-item .safe-pic img {
  width: 100%;
}
.product-safe .safe-box-item .box-item .safe-content {
  margin-left: 40px;
  max-width: 410px;
}
.product-safe .safe-box-item .box-item .safe-content .safe-title {
  font-size: 22px;
  color: #333333;
}
.product-safe .safe-box-item .box-item .safe-content .safe-des {
  margin-top: 10px;
  font-size: 16px;
  color: #666666;
  line-height: 1.5;
}
.technology-banner {
  background-image: url(../images/technology/banner.png);
  height: 470px;
}
.technology-nav {
  background-color: white;
}
.technology-nav .nav-box {
  height: 68px;
  display: flex;
  justify-content: space-around;
}
.technology-nav .nav-item {
  width: 180px;
  text-align: center;
  border-bottom: 2px solid transparent;
  font-size: 22px;
  line-height: 66px;
}
.technology-nav .nav-item a {
  display: inline-block;
  height: 100%;
  width: 100%;
  color: #666666;
}
.technology-nav .active {
  border-bottom: 2px solid #094eb9;
}
.technology-nav .active a {
  color: #094eb9;
}
.technology-nav-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 0.35);
}
.technology-box .anchor {
  position: relative;
  top: -68px;
}
.technology-box .item {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.technology-box .item .content {
  max-width: 310px;
}
.technology-box .item .content .title {
  font-size: 26px;
  color: #333333;
}
.technology-box .item .content .des {
  margin-top: 20px;
  font-size: 16px;
  color: #666666;
  line-height: 1.5;
}
.technology-box .item .pic {
  width: 718px;
  height: 414px;
}
.technology-box .item .pic img {
  width: 100%;
  height: 100%;
}
.technology-box .bg-gray {
  background-color: #f4f4f4;
}
body {
  position: relative;
}
#root {
  height: 100%;
}
.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
.banner {
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-title {
  color: white;
  font-size: 40px;
}
.banner-button {
  margin-top: 40px;
  width: 186px;
}
.banner-info {
  font-size: 16px;
  width: 70%;
  max-width: 1000px;
  text-align: center;
  color: white;
  margin-top: 30px;
  line-height: 1.5;
}
.record-empty {
  text-align: center;
  margin: 50px;
}
.record-empty img {
  width: 300px;
}
.record-empty .text {
  font-size: 16px;
  color: #999999;
}
